<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{$t("scheduleAttPosition.title")}}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('Search.search')"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{$t("scheduleAttPosition.create")}}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listAttractEmployee.length >0">
          <thead>
          <tr>
            <th class="text-start">
              {{$t("scheduleAttPosition.no")}}
            </th>
            <th class="text-left">
              {{$t("scheduleAttPosition.name")}}
            </th>
            <th class="text-left">
              {{$t("scheduleAttPosition.salary")}}
            </th>
            <th class="text-left">

            </th>
            <th class="text-left">

            </th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listAttractEmployee" :key="idx">
            <td>
              {{ (pagination.current_page * 10) - 10 + idx + 1 }}
            </td>
            <td>
              {{ item.name }}
            </td>

            <td>
              {{ item.salary }}
            </td>
            <td>
              {{ item.currency_short }}
            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"

                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>

        <default-table-no-result v-else/>
        <ModalDelete>
          <template v-slot="{close}">
            <Delete :item="item" @close="close" @success="fetchAttractEmployee()"/>
          </template>
        </ModalDelete>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchAttractEmployee"
        >
        </Pagination>
        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import Delete from "../../../views/MainCompany/scheduleAttractPosition/Delete";
import defaultTableNoResult from "../../../components/defaultTableNoResult";

export default {
  components: {
    Loading,
    Pagination,
    Delete,
    defaultTableNoResult
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: true,
      listAttractEmployee: [],
      item: {},

    }
  },
  methods: {
    searchFilterItem() {
      this.fetchAttractEmployee();
    },
    onCreate() {
      this.$router.push({name: 'scheduleAttractPosition.create'}).catch(() => {
      });
    },

    onDeleted(item) {
      this.item = item
      this.$store.commit("modalDelete_State", true);
    },

    fetchAttractEmployee() {
      this.isLoading = true;
      const id = this.$route.params.schedule_id;
      this.$axios.get(`company/check-in-schedule/${id}/all`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.isLoading = false;
          this.listAttractEmployee = res.data.data.data;
          this.pagination = res.data.data.pagination;
          if (!this.listAttractEmployee.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
      })
    },
  },
  created() {
    this.fetchAttractEmployee();
  }
}
</script>

<style scoped lang="scss">
</style>
